import React, { createContext, ReactNode, useState } from 'react';

type SessionContextModel = {
  sessionId?: string;
  setSessionId: (value?: string) => void;
};

export const SessionContext = createContext<SessionContextModel | undefined>(undefined);

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const [sessionId, setSessionId] = useState<string | undefined>();

  const contextValue: SessionContextModel = {
    sessionId: sessionId,
    setSessionId: setSessionId,
  };

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
